import React, {Component} from 'react';

import Image from 'next/image';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Divider} from 'Frontend/assets/images/icons';
import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * FeatureChecklist
 *
 * @property {*} prop Prop comment.
 *
 * @class FeatureChecklist
 * @extends {Component}
 */
class FeatureChecklist extends Component {
    static propTypes = {list: PropTypes.array.isRequired}

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof FeatureChecklist
     */
    render() {
        const {list} = this.props;

        return (
            <FeatureChecklistElement>
                <CheckList>
                    {list.map(item => {
                        const {
                            boldText,
                            icon: {
                                alt: iconAlt,
                                dimensions: {
                                    height: iconHeight,
                                    width: iconWidth
                                },
                                publicPath: {src: iconSrc},
                                title: iconTitle
                            },
                            text
                        } = item;

                        return (
                            <ListItem key={boldText}>
                                <IconWrap>
                                    <Image
                                        alt={iconAlt}
                                        height={iconHeight}
                                        layout="responsive"
                                        loading="eager"
                                        src={iconSrc}
                                        title={iconTitle}
                                        width={iconWidth}
                                    />
                                </IconWrap>
                                <TextWrap>
                                    <Strong>{boldText}</Strong><br />{text}
                                </TextWrap>
                            </ListItem>
                        );
                    })}
                </CheckList>
                <DividerPlacing>
                    <Divider />
                </DividerPlacing>
            </FeatureChecklistElement>
        );
    }
}

export default FeatureChecklist;

const FeatureChecklistElement = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    max-width: 100%;
    position: relative;
    width: max(290px, ${pxToVw(327, 'm')});

    ${MEDIA.DESKTOP.UP} {
        max-width: max(1320px, ${pxToVw(1320, 'd')});
        width: 100%;
    }
`;

const CheckList = styled.div`
    --gap: ${clampCss(16, 'm')};
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    max-width: calc(1288px + var(--gap));
    width: 100%;

    & > * {
        margin-top: var(--gap);
        margin-left: var(--gap);
    }

    ${MEDIA.TABLET.UP} {
        --gap: ${clampCss(24, 't')};
        flex-direction: row;
        flex-wrap: nowrap;
    }

    ${MEDIA.DESKTOP.UP} {
        --gap: max(32px, ${pxToVw(24, 'd')});
        flex-direction: row;
    }
`;

const ListItem = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    min-width: 267px;

    ${MEDIA.TABLET.UP} {
        flex: 1 1 33%;
        flex-direction: column;
        min-width: initial;
    }

    ${MEDIA.DESKTOP.UP} {
        flex: 0 0 max(267px, ${pxToVw(267, 'd')});
        flex-direction: column;
        min-width: max(267px, ${pxToVw(267, 'd')});
    }
`;

const IconWrap = styled.div`
    filter: sepia(100%) saturate(300%) brightness(200%) hue-rotate(325deg);
    flex: 0 0 ${clampCss(28, 'm')};
    height: ${clampCss(32, 'm')};
    margin-bottom: ${clampCss(14, 'm')};
    width: ${clampCss(32, 'm')};

    ${MEDIA.TABLET.UP} {
        flex: 0 0 ${clampCss(28, 't')};
        height: ${clampCss(70, 't')};
        margin-bottom: ${clampCss(14, 't')};
        width: ${clampCss(70, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        flex: 0 0 max(32px, ${pxToVw(32, 'd')});
        height: max(70px, ${pxToVw(70, 'd')});
        margin-bottom: max(21px, ${pxToVw(21, 'd')});
        width: max(70px, ${pxToVw(70, 'd')});
    }
`;

const TextWrap = styled.p`
    color: ${({theme}) => theme.colors.textColor};
    ${FONTS.REGULAR}
    font-size: ${clampCss(12, 'm')};
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};
    margin: 0;
    text-align: center;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(14, 't')};
        letter-spacing: ${clampCss(1, 't')};
        line-height: ${clampCss(20, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(16px, ${pxToVw(16, 'd')});
        letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
        line-height: max(22px, ${pxToVw(22, 'd')});
    }
`;

const Strong = styled.strong`
    ${FONTS.SEMIBOLD}
`;

const DividerPlacing = styled.div`
    margin-bottom: 40px;
    margin-top: 100px;
`;
import React, {Component} from 'react';

import {autobind} from 'core-decorators';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'Frontend/components/Button';

import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * Cta
 *
 * @property {*} prop Prop comment.
 *
 * @class Cta
 * @extends {Component}
 */
class Cta extends Component {
    static propTypes = {
        button: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        isQuickLead: PropTypes.bool
    }

    static defaultProps = {isQuickLead: false}

    /**
     * Handle the survey tracking.
     *
     * @memberof Cta
     */
    @autobind
    handleTrack() {
        const {isQuickLead} = this.props;

        window.dataLayer.push({
            event: isQuickLead ? 'surveyShortInit' : 'surveyInit',
            PageTitle: document.title.innerText,
            URL: window.location.href
        });
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Cta
     */
    render() {
        const {button, description, isQuickLead} = this.props;
        const link = isQuickLead ? '/interview-short' : '/interview';

        return (
            <CtaElement>
                <CTAContainer>
                    <CTAWrap>
                        <CTADesc>{description}</CTADesc>
                        <Button link={link} text={button} onClick={this.handleTrack} />
                    </CTAWrap>
                </CTAContainer>
            </CtaElement>
        );
    }
}

export default Cta;

const CtaElement = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin-bottom: ${clampCss(107, 'm')};
    margin-left: auto;
    margin-right: auto;
    margin-top: ${clampCss(107, 'm')};
    max-width: 100%;
    position: relative;
    width: max(290px, ${pxToVw(327, 'm')});

    ${MEDIA.TABLET.UP} {
        margin-bottom: ${clampCss(235, 't')};
        margin-top: ${clampCss(235, 't')};
        flex-direction: row;
        justify-content: center;
    }

    ${MEDIA.DESKTOP.UP} {
        margin-bottom: max(235px, ${pxToVw(235, 'd')});
        margin-top: max(235px, ${pxToVw(235, 'd')});
        max-width: 100%;
        padding: 0;
        width: max(1320px, ${pxToVw(1320, 'd')});
    }
`;

const CTAContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${clampCss(40, 'm')};
    width: 100%;

    ${MEDIA.TABLET.UP} {
        justify-content: center;
        margin-bottom: 0;
        width: 33.33%;
    }
`;

const CTAWrap = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const CTADesc = styled.p`
    ${FONTS.SEMIBOLD}
    color: ${({theme}) => theme.colors.textColor};
    display: inline-block;
    font-size: ${clampCss(16, 'm')};
    letter-spacing: ${clampCss(1.2, 'm')};
    line-height: ${clampCss(22, 'm')};
    margin-bottom: ${clampCss(40, 'm')};
    text-align: center;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(16, 't')};
        letter-spacing: ${clampCss(1.2, 't')};
        line-height: ${clampCss(22, 't')};
        margin-bottom: ${clampCss(32, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(16px, ${pxToVw(16, 'd')});
        letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
        line-height: max(22px, ${pxToVw(22, 'd')});
        margin-bottom: max(32px, ${pxToVw(32, 'd')});
    }
`;
import React, {Component} from 'react';

import Image from 'next/image';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';


/**
 * HeaderChecklist
 *
 * @property {*} prop Prop comment.
 *
 * @class HeaderChecklist
 * @extends {Component}
 */
class HeaderChecklist extends Component {
    static propTypes = {list: PropTypes.array.isRequired}

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof HeaderChecklist
     */
    render() {
        const {list} = this.props;

        return (
            <CheckList>
                {list.map(item => {
                    const {
                        boldText,
                        icon: {
                            alt: iconAlt,
                            dimensions: {
                                height: iconHeight,
                                width: iconWidth
                            },
                            publicPath: {src: iconSrc},
                            title: iconTitle
                        },
                        text
                    } = item;

                    return (
                        <ListItem key={boldText}>
                            <IconWrap>
                                <Image
                                    alt={iconAlt}
                                    height={iconHeight}
                                    layout="responsive"
                                    loading="eager"
                                    src={iconSrc}
                                    title={iconTitle}
                                    width={iconWidth}
                                />
                            </IconWrap>
                            <TextWrap>
                                <Strong>{boldText}</Strong> {text}
                            </TextWrap>
                        </ListItem>
                    );
                })}
            </CheckList>
        );
    }
}

export default HeaderChecklist;


const CheckList = styled.ul`
    margin: ${clampCss(24, 'm')} 0;
    padding: 0;

    ${MEDIA.TABLET.UP} {
        margin: ${clampCss(32, 't')} 0;
    }

    ${MEDIA.DESKTOP.UP} {
        margin: max(48px, ${pxToVw(48, 'd')}) 0;
    }
`;

const ListItem = styled.li`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 ${clampCss(12, 'm')} 0;

    &:last-child {
        margin: 0;
    }

    ${MEDIA.TABLET.UP} {
        margin: 0 0 ${clampCss(24, 't')} 0;
    }

    ${MEDIA.DESKTOP.UP} {
        margin: 0 0 max(32px, ${pxToVw(32, 'd')}) 0;
    }
`;

const IconWrap = styled.div`
    filter: grayscale(1) brightness(0) invert(1);
    flex: 0 0 ${clampCss(28, 'm')};
    height: ${clampCss(28, 'm')};
    margin: 0 ${clampCss(14, 'm')} 0 0;
    width: ${clampCss(28, 'm')};

    ${MEDIA.TABLET.UP} {
        flex: 0 0 ${clampCss(28, 't')};
        height: ${clampCss(28, 't')};
        margin: 0 ${clampCss(14, 't')} 0 0;
        width: ${clampCss(28, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        flex: 0 0 max(32px, ${pxToVw(32, 'd')});
        height: max(32px, ${pxToVw(32, 'd')});
        margin: 0 max(21px, ${pxToVw(21, 'd')}) 0 0;
        width: max(32px, ${pxToVw(32, 'd')});
    }
`;

const TextWrap = styled.p`
    ${FONTS.REGULAR}
    color: #ffffff;
    font-size: ${clampCss(12, 'm')};
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};
    margin: 0;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(14, 't')};
        letter-spacing: ${clampCss(1, 't')};
        line-height: ${clampCss(20, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(16px, ${pxToVw(16, 'd')});
        letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
        line-height: max(22px, ${pxToVw(22, 'd')});
    }
`;

const Strong = styled.strong`
    ${FONTS.SEMIBOLD}
`;
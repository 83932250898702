import React, {Component} from 'react';

import Link from 'next/link';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Arrow} from 'Frontend/assets/images/icons';
import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, iconAsBackground, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * Steps
 *
 * @property {*} prop Prop comment.
 *
 * @class Steps
 * @extends {Component}
 */
class Steps extends Component {
    static propTypes = {
        internalLink: PropTypes.object.isRequired,
        steps: PropTypes.array.isRequired,
        internalSuffix: PropTypes.string
    }

    static defaultProps = {internalSuffix: null}

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Steps
     */
    render() {
        const {internalLink: {seoSlug} = {}, internalSuffix, steps} = this.props;

        return (
            <StepsElement>
                <StepsContainer>
                    {steps.map(step => {
                        const {description, icon, overline} = step;

                        return (
                            <StepWrap key={description} overline={overline}>
                                <StepIcon overline={overline}>
                                    <Image alt={icon.alt} src={icon.publicPath.src} title={icon.title} />
                                </StepIcon>
                                <TextWrapper overline={overline}>
                                    {overline && <OverlineText>{overline}</OverlineText>}
                                    <Description overline={overline}>{description}</Description>
                                </TextWrapper>
                            </StepWrap>
                        );
                    })}
                </StepsContainer>
                <LinkContainer>
                    <Link href={`/${seoSlug}${internalSuffix ? `#${internalSuffix}` : ''}`} passHref>
                        <LinkStyle>
                            So funktioniert es im Detail
                        </LinkStyle>
                    </Link>
                </LinkContainer>
            </StepsElement>
        );
    }
}

export default Steps;

const StepsElement = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${clampCss(114, 'm')};
    max-width: 100%;
    position: relative;
    width: max(290px, ${pxToVw(327, 'm')});

    ${MEDIA.TABLET.UP} {
        margin-top: ${clampCss(114, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        margin-top: max(176px, ${pxToVw(176, 'd')});
        max-width: max(1320px, ${pxToVw(1320, 'd')});
        width: 100%;
    }
`;

const StepsContainer = styled.div`
    --gap: ${clampCss(16, 'm')};
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    max-width: calc(865px + var(--gap));
    width: 100%;

    & > * {
        margin-top: var(--gap);
        margin-left: var(--gap);
    }

    ${MEDIA.TABLET.UP} {
        --gap: ${clampCss(24, 't')};
        flex-direction: row;
        flex-wrap: nowrap;
    }

    ${MEDIA.DESKTOP.UP} {
        --gap: max(32px, ${pxToVw(24, 'd')});
        flex-direction: row;
    }
`;

const StepWrap = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: 267px;

    ${MEDIA.TABLET.UP} {
        flex: 1 1 33%;
        flex-direction: column;
        min-width: initial;
    }

    ${MEDIA.DESKTOP.UP} {
        flex: 0 0 max(267px, ${pxToVw(267, 'd')});
        flex-direction: column;
        min-width: max(267px, ${pxToVw(267, 'd')});
    }
`;

const StepIcon = styled.div`
    ${FONTS.SEMIBOLD}
    align-items: center;
    display: flex;
    font-size: ${clampCss(32, 'm')};
    height: ${clampCss(128, 'm')};
    justify-content: center;
    letter-spacing: 1.33px;
    position: relative;
    text-transform: uppercase;
    width: 100%;
    z-index: 1;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(20, 't')};
        height: ${clampCss(128, 't')};
        letter-spacing: ${clampCss(0.84, 't')};
        width: 100%;
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(32px, ${pxToVw(32, 'd')});
        height: max(128px, ${pxToVw(128, 'd')});
        letter-spacing: max(1.33px, ${pxToVw(1.33, 'd')});
        top: 0;
    }
`;

const Image = styled.img`
    height: ${clampCss(100, 'm')};
    width: ${clampCss(126, 'm')};

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(100, 't')};
        width: ${clampCss(126, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(100px, ${pxToVw(100, 'd')});
        width: max(126px, ${pxToVw(126, 'd')});
    }
`;

const TextWrapper = styled.div`
    margin: 0;

    ${MEDIA.TABLET.UP} {
        margin: 0;
    }

    ${MEDIA.DESKTOP.UP} {
        margin: 0;
    }
`;

const OverlineText = styled.p`
    ${FONTS.SEMIBOLD}
    font-size: ${clampCss(12, 'm')};
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};
    margin: ${clampCss(19, 'm')} 0 0 0;
    text-align: center;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(12, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};
        margin: ${clampCss(19, 't')} 0 0 0;
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(14px, ${pxToVw(14, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(19.6px, ${pxToVw(19.6, 'd')});
        margin: max(19px, ${pxToVw(19, 'd')});
    }
`;

const Description = styled.p`
    ${FONTS.REGULAR}
    font-size: ${clampCss(16, 'm')};
    letter-spacing: ${clampCss(1.2, 'm')};
    line-height: ${clampCss(22, 'm')};
    margin: ${clampCss(8, 'm')} 0 0 0;
    text-align: center;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(16, 't')};
        letter-spacing: ${clampCss(1.2, 't')};
        line-height: ${clampCss(22, 't')};
        margin: ${clampCss(8, 't')} 0 0 0;
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(16px, ${pxToVw(16, 'd')});
        letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
        line-height: max(22px, ${pxToVw(22, 'd')});
        margin: max(8px, ${pxToVw(8, 'd')}) 0 0 0;
    }
`;

const LinkContainer = styled.div`
    border-top: 1px solid #C8C8D0;
    margin: ${clampCss(66, 'm')} 0 0 0;
    max-width: 850px;
    padding-top: ${clampCss(40, 'm')};
    text-align: right;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        margin: ${clampCss(32, 't')} 0 0 0;
        padding-top: ${clampCss(48, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        margin: max(32px, ${pxToVw(32, 'd')}) 0 0 0;
        padding-top: max(48px, ${pxToVw(48, 'd')});
    }
`;

const LinkStyle = styled.a`
    ${FONTS.SEMIBOLD}
    color: ${({theme}) => theme.colors.textColor};
    cursor: pointer;
    display: inline-block;
    font-size: ${clampCss(12, 'm')};
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};
    padding: 0 ${clampCss(24, 'm')} 0 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    &::after {
        background-image: url(${iconAsBackground(Arrow)});
        background-position: right;
        background-size: ${clampCss(32, 'm')} 100%;
        content: '';
        height: ${clampCss(16, 'm')};
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        transition: width 0.2s 0s ease-in-out;
        width: ${clampCss(16, 'm')};
        will-change: width;
    }

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(12, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};
        padding: 0 ${clampCss(24, 't')} 0 0;

        &::after {
            background-size: ${clampCss(32, 't')} 100%;
            height: ${clampCss(16, 't')};
            width: ${clampCss(16, 't')};
        }
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(14px, ${pxToVw(14, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(19.6px, ${pxToVw(19.6, 'd')});
        padding: 0 max(24px, ${pxToVw(24, 'd')}) 0 0;

        &::after {
            background-size: max(32px, ${pxToVw(32, 'd')}) 100%;
            height: max(16px, ${pxToVw(16, 'd')});
            width: max(16px, ${pxToVw(16, 'd')});
        }
    }
`;
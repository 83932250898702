import React, {Component} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Avatar, Star} from 'Frontend/assets/images/icons';
import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, iconAsBackground, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * Feedback
 *
 * @property {*} prop Prop comment.
 *
 * @class Feedback
 * @extends {Component}
 */
class Feedback extends Component {
    static propTypes = {
        name: PropTypes.string,
        rating: PropTypes.number
    }

    static defaultProps = {
        name: '',
        rating: 0
    }

    /**
     * Gets the avarage rating.
     *
     * @returns {Number} The avarage rating.
     * @memberof Card
     */
    getStars() {
        const STAR_MAX = 5;
        const PERCENT = 100;
        const stars = new Array(STAR_MAX);
        let {rating} = this.props;

        // eslint-disable-next-line @nfq/no-magic-numbers
        rating /= 2;
        const fullStars = Math.trunc(rating);
        const percent = `${((rating - fullStars) * PERCENT).toFixed(0)}%`;

        stars.fill('100%', 0, fullStars);
        stars.fill(percent, fullStars, fullStars + 1);
        stars.fill('0%', fullStars + 1, STAR_MAX);

        return stars;
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Feedback
     */
    render() {
        const {name} = this.props;

        return (
            <FeedbackElement>
                <StyledAvatar />
                <Info>
                    <Name>{name}</Name>
                    <StarWrap>
                        {/* eslint-disable-next-line react/no-array-index-key */}
                        {this.getStars().map((item, index) => <StarDiv key={index} fill={item} />)}
                    </StarWrap>
                </Info>
            </FeedbackElement>
        );
    }
}

export default Feedback;

const FeedbackElement = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const StyledAvatar = styled(Avatar)`
    flex: 0 0 ${clampCss(72, 'm')};
    height: ${clampCss(72, 'm')};
    margin-right: ${clampCss(16, 'm')};
    width: ${clampCss(72, 'm')};

    ${MEDIA.TABLET.UP} {
        flex: 0 0 ${clampCss(72, 't')};
        height: ${clampCss(72, 't')};
        margin-right: ${clampCss(16, 't')};
        width: ${clampCss(72, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        flex: 0 0 max(72px, ${pxToVw(72, 'd')});
        height: max(72px, ${pxToVw(72, 'd')});
        margin-right: max(16px, ${pxToVw(16, 'd')});
        width: max(72px, ${pxToVw(72, 'd')});
    }
`;

const Info = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
`;

const Name = styled.p`
    ${FONTS.REGULAR}
    color: ${({theme}) => theme.colors.textColor};
    font-size: ${clampCss(16, 'm')};
    letter-spacing: ${clampCss(1.2, 'm')};
    line-height: ${clampCss(22, 'm')};
    margin: 0 0 ${clampCss(4, 'm')} 0;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(16, 't')};
        letter-spacing: ${clampCss(1.2, 't')};
        line-height: ${clampCss(22, 't')};
        margin: 0 0 ${clampCss(4, 't')} 0;
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(16px, ${pxToVw(16, 'd')});
        letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
        line-height: max(22px, ${pxToVw(22, 'd')});
        margin: 0 0 max(4px, ${pxToVw(4, 'd')}) 0;
    }
`;

const StarWrap = styled.div`
    --gap: ${clampCss(8, 'm')};
    align-items: center;
    display: inline-flex;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));

    & > * {
        margin-left: var(--gap);
        margin-top: var(--gap);
    }

    ${MEDIA.TABLET.UP} {
        --gap: ${clampCss(8, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        --gap: max(8px, ${pxToVw(8, 'd')});
    }
`;

const StarDiv = styled.div`
    background-image:
        url(${iconAsBackground(Star, {bgColor: '#FAF6F4'})}),
        linear-gradient(to right, #D56B50 0%, #D56B50 ${({fill}) => fill}, #fff ${({fill}) => fill}, #fff 100%);
    background-size: cover;
    height: 12px;
    width: 12px;
`;
import React, {Component} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Divider} from 'Frontend/assets/images/icons';
import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * Intro
 *
 * @property {*} prop Prop comment.
 *
 * @class Intro
 * @extends {Component}
 */
class Intro extends Component {
    static propTypes = {
        bold: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Intro
     */
    render() {
        const {bold, text} = this.props;

        return (
            <IntroElement>
                <IntroContainer>
                    <Text bold>{bold}</Text>
                    <DividerPlacing>
                        <Divider />
                    </DividerPlacing>
                    <Text>{text}</Text>
                </IntroContainer>
            </IntroElement>
        );
    }
}

export default Intro;

const IntroElement = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    position: relative;
    width: max(290px, ${pxToVw(327, 'm')});

    ${MEDIA.DESKTOP.UP} {
        padding: 15px;
        width: 100%;
    }
`;

const IntroContainer = styled.div`
    width: 100%;

    ${MEDIA.TABLET.UP} {
        width: 50%;
    }

    ${MEDIA.DESKTOP.UP} {
        width: 33%;
    }
`;

const Text = styled.div`
    ${({bold}) => (bold ? FONTS.SEMIBOLD : FONTS.LIGHT)}
    color: ${({theme}) => theme.colors.textColor};
    font-size: ${clampCss(16, 'm')};
    letter-spacing: ${clampCss(1.2, 'm')};
    line-height: ${clampCss(22, 'm')};
    text-align: center;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(16, 't')};
        letter-spacing: ${clampCss(1.2, 't')};
        line-height: ${clampCss(22, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(16px, ${pxToVw(16, 'd')});
        letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
        line-height: max(22px, ${pxToVw(22, 'd')});
    }
`;

const DividerPlacing = styled.div`
    align-items: center;
    display: flex;
    height: ${clampCss(82, 'm')};
    justify-content: center;
    margin: ${clampCss(8, 'm')} 0;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(82, 't')};
        margin: ${clampCss(8, 't')} 0;
        width: 100%;
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(82px, ${pxToVw(82, 'd')});
        margin: max(8px, ${pxToVw(8, 'd')}) 0;
        width: 100%;
    }
`;
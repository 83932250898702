import React, {Component} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Cta from 'Frontend/components/mainPage/CTA';
import FeatureChecklist from 'Frontend/components/mainPage/FeatureChecklist';
import Group from 'Frontend/components/mainPage/Group';
import Header from 'Frontend/components/mainPage/Header';
import InfoFooter from 'Frontend/components/mainPage/InfoFooter';
import Intro from 'Frontend/components/mainPage/Intro';
import Quality from 'Frontend/components/mainPage/Quality';
import Steps from 'Frontend/components/mainPage/Steps';
import LongCopyImage from 'Frontend/components/modules/LongCopyImage';
import PageLayout from 'Frontend/components/PageLayout';
import {PageConfig, Review} from 'Server/models';

import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';
import dbConnect from 'Server/utils/dbConnect';
import {
    populateFooterItems,
    populateGroups,
    populateHeader,
    populateMenuItems,
    populateQuality,
    populateSteps,
    populateTextImage
} from 'Server/utils/pagePopulations';

/**
 * Home Page class.
 *
 * @export
 * @class Home
 * @extends {Component}
 */
export default class Home extends Component {
    static propTypes = {
        footer: PropTypes.object,
        menu: PropTypes.object,
        page: PropTypes.shape({
            cta: PropTypes.object.isRequired,
            group: PropTypes.array.isRequired,
            header: PropTypes.object.isRequired,
            intro: PropTypes.object.isRequired,
            quality: PropTypes.object.isRequired,
            reviews: PropTypes.array.isRequired,
            steps: PropTypes.object.isRequired,
            textImage: PropTypes.object.isRequired,
            // eslint-disable-next-line @nfq/sort-keys
            interviewShort: PropTypes.object,
            seoDescription: PropTypes.string,
            seoKeywordList: PropTypes.array,
            // eslint-disable-next-line react/boolean-prop-naming
            seoNoFollow: PropTypes.bool,
            // eslint-disable-next-line react/boolean-prop-naming
            seoNoIndex: PropTypes.bool,
            seoPageTitle: PropTypes.string
        }),
        router: PropTypes.object
    }

    static defaultProps = {
        footer: null,
        menu: null,
        page: {
            interviewShort: null,
            seoDescription: '',
            seoKeywordList: [],
            seoNoFollow: false,
            seoNoIndex: false,
            seoPageTitle: ''
        },
        router: null
    }

    /**
     * Gets the layout for this page.
     *
     * @static
     * @param {Object} router        The router object from nextjs.
     * @param {Object} pageProps     All page props given from server.
     * @param {Object} PageComponent The page component to render.
     *
     * @returns {JSX} The complete page.
     * @memberof Home
     */
    static getLayout(router, pageProps, PageComponent) {
        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <PageLayout router={router} {...pageProps} isLightMode>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <PageComponent router={router} {...pageProps} />
            </PageLayout>
        );
    }

    /**
     * Renders the Component.
     *
     * @returns {Component} React Component.
     * @memberof Home
     */
    render() {
        const {
            page: {cta, group, header, interviewShort, intro, quality, reviews, steps, textImage},
            router
        } = this.props;
        const isQuickLeadEnabled = header?.isQuickLeadEnabled === true;
        let headerImage = {};

        // Use alternative header image if available and query param is set.
        const queryKey = 'h';

        // Get the query param a little bit more inconvenient, because
        // when using `const {h} = router.query`, h will be undefined on the initial render.

        // eslint-disable-next-line security/detect-non-literal-regexp
        const h = router.query[String(queryKey)] || router.asPath.match(new RegExp(`[&?]${queryKey}=(.*?)(&|$)`, 'u'))?.[1];

        if (header) {
            ({headerImage} = header);
        }

        if (header && h === 'alt' && header.altHeaderImage) {
            headerImage = header.altHeaderImage;
        }

        return (
            <>
                {/* eslint-disable react/jsx-props-no-spreading */}
                {header && (
                    <Header
                        copy={header.copy}
                        ctaText={header.ctaText}
                        headerImage={headerImage}
                        headline={header.headline}
                        headlineBold={header.headlineBold}
                        interviewShort={interviewShort}
                        isQuickLeadEnabled={isQuickLeadEnabled}
                        list={header.list}
                    />
                )}
                {isQuickLeadEnabled && <FeatureChecklist list={header.list} />}
                {!isQuickLeadEnabled && <Spacer />}
                {intro && <Intro isQuickLeadEnabled={isQuickLeadEnabled} {...intro} />}
                {steps && <Steps {...steps} />}
                {textImage && <MainPageLongCopyImage {...textImage} />}
                {group && <Group groups={group} />}
                {quality && <Quality {...quality} />}
                {cta && <Cta {...cta} />}
                {(reviews.length > 0) && <InfoFooter reviews={reviews} />}
                {/* eslint-enable react/jsx-props-no-spreading */}
            </>
        );
    }
}

const Spacer = styled.div`
    height: ${clampCss(80, 'm')};

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(80, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(80px, ${pxToVw(80, 'd')});
    }
`;

const MainPageLongCopyImage = styled(LongCopyImage)`
    margin-bottom: 0;
    margin-top: ${clampCss(131, 'm')};

    ${MEDIA.TABLET.UP} {
        margin-bottom: 0;
        margin-top: ${clampCss(159, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        margin-bottom: 0;
        margin-top: max(188px, ${pxToVw(188, 'd')});
    }
`;

/**
 * Gets static props needed for the actual page.
 *
 * @export
 * @param {Object} ctx    The context in which this function is called.
 * @param {Object} params The params given for this page.
 * @returns {Object} An props object or an redirect.
 */
export async function getStaticProps() {
    let menuJson = {};
    let footerJson = {};
    let pageJson = {};

    try {
        await dbConnect();
        const randomStart = Math.floor(Math.random() * await Review.estimatedDocumentCount().exec());
        const reviews = await Review.find({isApproved: true})
            .select('-_id name netPromoterScore')
            .skip(randomStart)
            // eslint-disable-next-line @nfq/no-magic-numbers
            .limit(3);

        const menu = await PageConfig.findOne({type: 'Menu'});
        const footer = await PageConfig.findOne({type: 'Footer'})
            .populate('partner')
            .populate('socialMedia.icon');
        const page = await PageConfig.findOne({type: 'MainPage'})
            .select('-createdAt -updatedAt');
        const interviewShort = await PageConfig.findOne({type: 'InterviewShort'});

        await populateGroups(page);
        await populateHeader(page);
        await populateQuality(page);
        await populateSteps(page);
        await populateTextImage(page);
        await populateMenuItems(menu.mainMenu);
        await populateFooterItems(footer.copyright);

        const pageObj = page.toObject();

        pageObj.reviews = reviews;
        pageObj.interviewShort = interviewShort;

        menuJson = JSON.stringify(menu.toObject());
        footerJson = JSON.stringify(footer.toObject());
        pageJson = JSON.stringify(pageObj);
    } catch (e) {
        menuJson = JSON.stringify({});
        footerJson = JSON.stringify({});
        pageJson = JSON.stringify({
            reviews: [],
            seoKeywordList: []
        });
    }

    const menuProp = JSON.parse(menuJson);

    menuProp.active = '/';

    return {
        props: {
            footer: JSON.parse(footerJson),
            menu: menuProp,
            page: JSON.parse(pageJson)
        },
        revalidate: 2
    };
}
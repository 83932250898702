import React, {Component} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Divider from 'Frontend/components/modules/Divider';
import ShortCopyImage from 'Frontend/components/modules/ShortCopyImage';

import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

const POS = {
    left: 'flex-start',
    right: 'flex-end'
};

/**
 * Group
 *
 * @property {*} prop Prop comment.
 *
 * @class Group
 * @extends {Component}
 */
class Group extends Component {
    static propTypes = {groups: PropTypes.array.isRequired}

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Group
     */
    render() {
        const {groups} = this.props;

        return (
            <GroupElement>
                <GroupHead />
                <GroupContainer>
                    {groups.map(group => {
                        const {headline, headlineBold, modules} = group;

                        return (
                            <React.Fragment key={headline}>
                                <HeadlineElement>
                                    <Headline>
                                        {headline}
                                        <HeadlineBold>{headlineBold}</HeadlineBold>
                                    </Headline>
                                </HeadlineElement>
                                {modules.map(shortCopy => (
                                    <React.Fragment key={shortCopy.headline}>
                                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                        <GroupShortCopy key={shortCopy.headline} {...shortCopy} isInGroup />
                                        <GroupDivider position={POS[shortCopy.imagePosition]} isInGroup />
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        );
                    })}
                </GroupContainer>
                <GroupBottom />
            </GroupElement>
        );
    }
}

export default Group;

const GroupElement = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    margin-top: ${clampCss(96, 'm')};

    ${MEDIA.TABLET.UP} {
        margin-top: ${clampCss(142, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        margin-top: max(224px, ${pxToVw(224, 'd')});
    }
`;

const GroupHead = styled.div`
    background: linear-gradient(to bottom right, #fff 0%, #fff 49.5%, #FAF6F4 50%, #FAF6F4 100%);
    height: ${clampCss(32, 'm')};
    width: 100%;

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(64, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(95px, ${pxToVw(95, 'd')});
        margin: 0 max(125.5px, ${pxToVw(125.5, 'd')}) 0 max(125.5px, ${pxToVw(125.5, 'd')});
        max-width: max(1069px, ${pxToVw(1069, 'd')});
        width: calc(100% - max(251px, ${pxToVw(251, 'd')}));
    }
`;

const GroupContainer = styled.div`
    background-color: #FAF6F4;
    padding: ${clampCss(94, 'm')} 0 0 0;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        padding: ${clampCss(41, 't')} 0 0 0;
    }

    ${MEDIA.DESKTOP.UP} {
        margin: 0 max(125.5px, ${pxToVw(125.5, 'd')}) 0 max(125.5px, ${pxToVw(125.5, 'd')});
        max-width: max(1069px, ${pxToVw(1069, 'd')});
        padding: max(41px, ${pxToVw(41, 'd')}) 0 0 0;
        width: calc(100% - max(251px, ${pxToVw(251, 'd')}));
    }
`;

const GroupBottom = styled.div`
    background: linear-gradient(to top left, #fff 0%, #fff 49.5%, #FAF6F4 50%, #FAF6F4 100%);
    height: ${clampCss(32, 'm')};
    width: 100%;

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(64, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(95px, ${pxToVw(95, 'd')});
        margin: 0 max(125.5px, ${pxToVw(125.5, 'd')}) 0 max(125.5px, ${pxToVw(125.5, 'd')});
        max-width: max(1069px, ${pxToVw(1069, 'd')});
        width: calc(100% - max(251px, ${pxToVw(251, 'd')}));
    }
`;

const HeadlineElement = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: ${clampCss(48, 'm')};
    margin-left: auto;
    margin-right: auto;
    margin-top: ${clampCss(32, 'm')};
    max-width: 100%;
    position: relative;
    width: max(290px, ${pxToVw(327, 'm')});

    &:first-child {
        margin-top: 0;
    }

    ${MEDIA.TABLET.UP} {
        margin-bottom: ${clampCss(48, 't')};
        margin-top: ${clampCss(64, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        margin-bottom: max(48px, ${pxToVw(48, 'd')});
        margin-top: max(64px, ${pxToVw(64, 'd')});
        max-width: 100%;
        padding: 0 max(110px, ${pxToVw(110, 'd')}) 0 max(110px, ${pxToVw(110, 'd')});
        width: max(1320px, ${pxToVw(1320, 'd')});
    }
`;

const Headline = styled.h2`
    ${FONTS.LIGHT}
    color: ${({theme}) => theme.colors.textColor};
    font-size: ${clampCss(24, 'm')};
    letter-spacing: ${clampCss(1.2, 'm')};
    line-height: ${clampCss(31.2, 'm')};
    margin: 0;
    text-align: left;
    text-transform: uppercase;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(24, 't')};
        letter-spacing: ${clampCss(1.2, 't')};
        line-height: ${clampCss(31.2, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(32px, ${pxToVw(32, 'd')});
        letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
        line-height: max(38.4px, ${pxToVw(38.4, 'd')});
    }
`;

const HeadlineBold = styled.span`
    ${FONTS.MEDIUM}
    display: block;
    text-align: left;
`;

const GroupShortCopy = styled(ShortCopyImage)`
    margin-bottom: ${clampCss(32, 'm')};
    margin-top: 0;

    ${MEDIA.TABLET.UP} {
        margin-bottom: ${clampCss(22, 't')};
        margin-top: 0;
    }

    ${MEDIA.DESKTOP.UP} {
        margin-bottom: max(22px, ${pxToVw(22, 'd')});
        margin-top: 0;
    }
`;

const GroupDivider = styled(Divider)`
    margin-bottom: ${clampCss(32, 'm')};
    margin-top: 0;

    ${MEDIA.TABLET.UP} {
        margin-bottom: 0;
        margin-top: 0;

        &:last-child {
            margin-bottom: ${clampCss(32, 't')};
        }
    }

    ${MEDIA.DESKTOP.UP} {
        margin-bottom: 0;
        margin-top: 0;

        &:last-child {
            margin-bottom: max(32px, ${pxToVw(32, 'd')});
        }
    }
`;
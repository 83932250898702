/* eslint-disable max-lines, max-lines-per-function */
import React, {Component} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Feedback from 'Frontend/components/mainPage/Feedback';

import {Quotation} from 'Frontend/assets/images/icons';
import {clampCss, iconAsBackground, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * InfoFooter
 *
 * @property {*} prop Prop comment.
 *
 * @class InfoFooter
 * @extends {Component}
 */
class InfoFooter extends Component {
    static propTypes = {reviews: PropTypes.array.isRequired}

    static defaultProps = {}

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof InfoFooter
     */
    render() {
        const {reviews} = this.props;

        return (
            <InfoFooterElement>
                <InfoFooterHead>
                    <Bubble />
                </InfoFooterHead>
                <InfoFooterContainer>
                    <Info>
                        <Row>
                            {reviews.map(review => (
                                <Column key={review.name} show>
                                    <Feedback name={review.name} rating={review.netPromoterScore} />
                                </Column>
                            ))}
                        </Row>
                    </Info>
                </InfoFooterContainer>
            </InfoFooterElement>
        );
    }
}

export default InfoFooter;

const InfoFooterElement = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: ${clampCss(96, 'm')};

    ${MEDIA.TABLET.UP} {
        margin-top: ${clampCss(142, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        margin-top: max(224px, ${pxToVw(224, 'd')});
    }
`;

const InfoFooterHead = styled.div`
    background: linear-gradient(to bottom right, #fff 0%, #fff 49.5%, #FAF6F4 50%, #FAF6F4 100%);
    height: ${clampCss(32, 'm')};
    position: relative;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(64, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(95px, ${pxToVw(95, 'd')});
        margin: 0 max(125.5px, ${pxToVw(125.5, 'd')}) 0 max(125.5px, ${pxToVw(125.5, 'd')});
    }
`;

const Bubble = styled.div`
    background-color: #28272E;
    background-image: url(${iconAsBackground(Quotation)});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: ${clampCss(24, 'm')} ${clampCss(21, 'm')};
    border-radius: 50%;
    bottom: 0;
    height: ${clampCss(80, 'm')};
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    width: ${clampCss(80, 'm')};

    ${MEDIA.TABLET.UP} {
        background-size: ${clampCss(24, 't')} ${clampCss(21, 't')};
        height: ${clampCss(80, 't')};
        width: ${clampCss(80, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        background-size: max(24px, ${pxToVw(24, 'd')}) max(21px, ${pxToVw(21, 'd')});
        height: max(80px, ${pxToVw(80, 'd')});
        width: max(80px, ${pxToVw(80, 'd')});
    }
`;

const InfoFooterContainer = styled.div`
    background-color: #FAF6F4;
    padding: ${clampCss(142, 'm')} 0 ${clampCss(142, 'm')} 0;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        padding: ${clampCss(142, 't')} 0 ${clampCss(142, 't')} 0;
    }

    ${MEDIA.DESKTOP.UP} {
        padding: max(142px, ${pxToVw(142, 'd')}) 0 max(142px, ${pxToVw(142, 'd')}) 0;
    }
`;

const Info = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: max(290px, ${pxToVw(327, 'm')});

    ${MEDIA.DESKTOP.UP} {
        max-width: 100%;
        width: max(848px, ${pxToVw(848, 'd')});
    }
`;

const Row = styled.div`
    --gap: ${clampCss(32, 'm')};
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));

    & > * {
        margin-left: var(--gap);
        margin-top: var(--gap);
    }

    ${MEDIA.TABLET.UP} {
        --gap: ${clampCss(32, 't')};
        flex-wrap: nowrap;
    }

    ${MEDIA.DESKTOP.UP} {
        --gap: max(32px, ${pxToVw(32, 'd')});
        flex-wrap: nowrap;
    }
`;

const Column = styled.div`
    display: ${({show}) => (show ? null : 'none')};
    flex: 1 1 100%;
    max-width: 100%;
    position: relative;
    text-align: center;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        display: initial;
        flex: 1 1 calc(33% - (var(--gap) * 2 / 3));
        max-width: calc(33% - (var(--gap) * 2 / 3));
        text-align: left;
        width: calc(33% - (var(--gap) * 2 / 3));
    }

    ${MEDIA.DESKTOP.UP} {
        display: initial;
        flex: 1 1 calc(33% - (var(--gap) * 2 / 3));
        max-width: calc(33% - (var(--gap) * 2 / 3));
        width: calc(33% - (var(--gap) * 2 / 3));
    }
`;
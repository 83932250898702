import React, {Component} from 'react';

import {autobind} from 'core-decorators';
import Image from 'next/image';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'Frontend/components/Button';
import HeaderChecklist from 'Frontend/components/mainPage/HeaderChecklist';
import QuickLead from 'Frontend/components/modules/QuickLead';

import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * Header
 *
 * @property {*} prop Prop comment.
 *
 * @class Header
 * @extends {Component}
 */
class Header extends Component {
    static propTypes = {
        copy: PropTypes.string.isRequired,
        ctaText: PropTypes.string.isRequired,
        headerImage: PropTypes.object.isRequired,
        headline: PropTypes.string.isRequired,
        headlineBold: PropTypes.string.isRequired,
        list: PropTypes.array.isRequired,
        interviewShort: PropTypes.object,
        isQuickLeadEnabled: PropTypes.bool
    }

    static defaultProps = {
        interviewShort: {},
        isQuickLeadEnabled: false
    }

    /**
     * Handle the survey tracking.
     *
     * @memberof Cta
     */
    @autobind
    handleTrack() {
        window.dataLayer.push({
            event: 'surveyInit',
            PageTitle: document.title,
            URL: window.location.href
        });
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Header
     */
    render() {
        const {
            copy,
            ctaText,
            headerImage: {alt, dimensions: {height, width}, publicPath: {src}, title},
            headline,
            headlineBold,
            interviewShort,
            isQuickLeadEnabled,
            list
        } = this.props;

        return (
            <HeaderElement>
                <ImagePositionWrapper>
                    <HeaderImage>
                        <Image
                            alt={alt}
                            height={height}
                            layout="responsive"
                            loading="eager"
                            src={src}
                            title={title}
                            width={width}
                            priority
                        />
                    </HeaderImage>
                </ImagePositionWrapper>
                <HeaderTextWrapper>
                    <HeaderSize>
                        <Headline>
                            {headline}<br /><Bold>{headlineBold}</Bold>
                        </Headline>
                        <Copy>{copy}</Copy>
                        {!isQuickLeadEnabled && <HeaderChecklist list={list} />}
                        {!isQuickLeadEnabled && <Button link="/interview" text={ctaText} onClick={this.handleTrack} />}
                        {isQuickLeadEnabled && (
                            <StyledQuickLead
                                productPlaceholder={interviewShort?.productPlaceholder}
                                searchInfo={interviewShort?.searchInfo}
                                searchPlaceholder={interviewShort?.searchPlaceholder}
                                searchTitle={interviewShort?.searchTitle}
                            />
                        )}
                    </HeaderSize>
                </HeaderTextWrapper>
                <HeaderSlope />
            </HeaderElement>
        );
    }
}

export default Header;

const StyledQuickLead = styled(QuickLead)`
    margin-bottom: ${clampCss(20, 'm')};
    margin-top: 50px;
    z-index: 1;

    ${MEDIA.TABLET.UP} {
        margin-bottom: ${clampCss(40, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        margin-bottom: max(60px, ${pxToVw(60, 'd')});
    }
`;

const HeaderElement = styled.div`
    background-color: #627068;
    background-image: linear-gradient(180deg, rgba(53,60,72,0) 66%, rgba(46,53,66,0.5) 100%);
    height: 100%;
    padding-bottom: ${clampCss(33, 'm')};
    position: relative;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        padding-bottom: ${clampCss(65, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        padding-bottom: max(96px, ${pxToVw(96, 'd')});
    }
`;

const ImagePositionWrapper = styled.div`
    bottom: 0;
    left: 0;
    margin: 0 auto;
    max-width: max(1512px, ${pxToVw(1512, 'd')});
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
`;

const HeaderImage = styled.div`
    bottom: 0;
    height: ${clampCss(391, 'm')};
    position: absolute;
    right: ${clampCss(-90, 'm', true)};
    width: ${clampCss(396, 'm')};

    ${MEDIA.TABLET.UP} {
        bottom: 0;
        height: ${clampCss(567, 't')};
        right: ${clampCss(-45, 't', true)};
        width: ${clampCss(576, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        bottom: max(73px, ${pxToVw(73, 'd')});
        height: max(744px, ${pxToVw(744, 'd')});
        right: 0px;
        width: max(756px, ${pxToVw(756, 'd')});
    }
`;

const HeaderTextWrapper = styled.div`
    margin: 0 auto;
    max-width: 100%;
    padding-top: ${clampCss(93, 'm')};
    position: relative;
    width: max(290px, ${pxToVw(327, 'm')});

    ${MEDIA.TABLET.UP} {
        padding-top: ${clampCss(128, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        padding-top: max(222px, ${pxToVw(222, 'd')});
        width: max(1320px, ${pxToVw(1320, 'd')});
    }
`;

const HeaderSize = styled.div`
    max-width: max(520px, ${pxToVw(520, 'd')});
`;

const Headline = styled.h1`
    ${FONTS.LIGHT}
    color: #ffffff;
    font-size: ${clampCss(28, 'm')};
    letter-spacing: ${clampCss(1.02, 'm')};
    line-height: ${clampCss(33.6, 'm')};
    margin: 0;
    text-transform: uppercase;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(42, 't')};
        letter-spacing: ${clampCss(1.02, 't')};
        line-height: ${clampCss(50.4, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(56px, ${pxToVw(56, 'd')});
        letter-spacing: max(1.46px, ${pxToVw(1.46, 'd')});
        line-height: max(67.2px, ${pxToVw(67.2, 'd')});
    }
`;

const Bold = styled.strong`
    ${FONTS.SEMIBOLD}
`;

const Copy = styled.p`
    ${FONTS.REGULAR}
    color: #ffffff;
    font-size: ${clampCss(16, 'm')};
    letter-spacing: ${clampCss(1.2, 'm')};
    line-height: ${clampCss(22, 'm')};
    margin: ${clampCss(16, 'm')} 0;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(16, 't')};
        letter-spacing: ${clampCss(1.2, 't')};
        line-height: ${clampCss(22, 't')};
        margin: ${clampCss(16, 't')} 0;
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(20px, ${pxToVw(20, 'd')});
        letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
        line-height: max(24px, ${pxToVw(24, 'd')});
        margin: max(24px, ${pxToVw(24, 'd')}) 0;
    }
`;

const HeaderSlope = styled.div`
    background: linear-gradient(to bottom right, transparent 0%, transparent 49.5%, #ffffff 50%, #ffffff 100%);
    bottom: -1px;
    height: ${clampCss(33, 'm')};
    left: 0;
    position: absolute;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(65, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(96px, ${pxToVw(96, 'd')});
        max-width: 100%;
        width: 100%;
    }
`;
import React, {Component} from 'react';

import Link from 'next/link';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Arrow} from 'Frontend/assets/images/icons';
import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, iconAsBackground, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';

/**
 * Quality
 *
 * @property {*} prop Prop comment.
 *
 * @class Quality
 * @extends {Component}
 */
class Quality extends Component {
    static propTypes = {
        headline: PropTypes.string.isRequired,
        headlineBold: PropTypes.string.isRequired,
        internalLink: PropTypes.object.isRequired,
        list: PropTypes.array.isRequired,
        internalSuffix: PropTypes.string
    }

    static defaultProps = {internalSuffix: null}

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Quality
     */
    render() {
        const {headline, headlineBold, internalLink: {seoSlug} = {}, internalSuffix, list} = this.props;

        return (
            <QualityElement>
                <HeadlineElement>
                    <Headline>
                        {headline}
                        <HeadlineBold>{headlineBold}</HeadlineBold>
                    </Headline>
                </HeadlineElement>
                <QualityContainer>
                    {list.map(item => {
                        const {headline: qualityHeadline, icon, text} = item;

                        return (
                            <QualityWrap key={qualityHeadline}>
                                <QualityHeadline>
                                    <Image alt={icon.alt} src={icon.publicPath.src} title={icon.title} />
                                    {qualityHeadline}
                                </QualityHeadline>
                                <TextWrapper>
                                    <Description>{text}</Description>
                                </TextWrapper>
                            </QualityWrap>
                        );
                    })}
                </QualityContainer>
                <LinkContainer>
                    <Link href={`/${seoSlug}${internalSuffix ? `#${internalSuffix}` : ''}`} passHref>
                        <LinkStyle>
                            Mehr erfahren
                        </LinkStyle>
                    </Link>
                </LinkContainer>
            </QualityElement>
        );
    }
}

export default Quality;

const QualityElement = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${clampCss(96, 'm')};
    max-width: 100%;
    position: relative;
    width: max(290px, ${pxToVw(327, 'm')});

    ${MEDIA.TABLET.UP} {
        margin-top: ${clampCss(142, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        margin-top: max(224px, ${pxToVw(224, 'd')});
        max-width: max(1320px, ${pxToVw(1320, 'd')});
        width: 100%;
    }
`;

const HeadlineElement = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: ${clampCss(48, 'm')};
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    position: relative;
    width: max(290px, ${pxToVw(327, 'm')});

    ${MEDIA.TABLET.UP} {
        margin-bottom: ${clampCss(61, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        margin-bottom: max(61px, ${pxToVw(61, 'd')});
        max-width: 1069px;
        width: 100%;
    }
`;

const Headline = styled.h2`
    ${FONTS.LIGHT}
    color: ${({theme}) => theme.colors.textColor};
    font-size: ${clampCss(24, 'm')};
    letter-spacing: ${clampCss(1.2, 'm')};
    line-height: ${clampCss(31.2, 'm')};
    margin: 0;
    text-align: left;
    text-transform: uppercase;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(24, 't')};
        letter-spacing: ${clampCss(1.2, 't')};
        line-height: ${clampCss(31.2, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(32px, ${pxToVw(32, 'd')});
        letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
        line-height: max(38.4px, ${pxToVw(38.4, 'd')});
    }
`;

const HeadlineBold = styled.span`
    ${FONTS.MEDIUM}
    display: block;
    text-align: left;
`;

const QualityContainer = styled.div`
    --gap: ${clampCss(16, 'm')};
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    max-width: calc(1069px + var(--gap));
    width: calc(100% + var(--gap));

    & > * {
        margin-left: var(--gap);
        margin-top: var(--gap);
    }

    ${MEDIA.TABLET.UP} {
        --gap: ${clampCss(24, 't')};
        flex-direction: row;
        flex-wrap: nowrap;
    }

    ${MEDIA.DESKTOP.UP} {
        --gap: max(32px, ${pxToVw(24, 'd')});
        flex-direction: row;
    }
`;

const QualityWrap = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: 240px;

    ${MEDIA.TABLET.UP} {
        flex: 1 1 33%;
        flex-direction: column;
        min-width: initial;
    }

    ${MEDIA.DESKTOP.UP} {
        flex: 0 0 max(240px, ${pxToVw(240, 'd')});
        flex-direction: column;
        min-width: max(240px, ${pxToVw(240, 'd')});
    }
`;

const QualityHeadline = styled.div`
    ${FONTS.REGULAR}
    align-items: center;
    display: flex;
    font-size: ${clampCss(20, 'm')};
    justify-content: flex-start;
    letter-spacing: ${clampCss(1.2, 'm')};
    line-height: ${clampCss(24, 'm')};
    position: relative;
    width: 100%;
    z-index: 1;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(20, 't')};
        letter-spacing: ${clampCss(1.2, 't')};
        line-height: ${clampCss(24, 't')};
        width: 100%;
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(20px, ${pxToVw(20, 'd')});
        letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
        line-height: max(24px, ${pxToVw(24, 'd')});
    }
`;

const Image = styled.img`
    height: ${clampCss(18, 'm')};
    margin-right: ${clampCss(14, 'm')};
    width: ${clampCss(18, 'm')};

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(18, 't')};
        margin-right: ${clampCss(14, 't')};
        width: ${clampCss(18, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(18px, ${pxToVw(18, 'd')});
        margin-right: max(14px, ${pxToVw(14, 'd')});
        width: max(18px, ${pxToVw(18, 'd')});
    }
`;

const TextWrapper = styled.div`
    margin-bottom: ${clampCss(48, 'm')};
    width: 100%;

    ${MEDIA.TABLET.UP} {
        margin-bottom: 0;
    }

    ${MEDIA.DESKTOP.UP} {
        margin-bottom: 0;
    }
`;

const Description = styled.p`
    ${FONTS.REGULAR}
    font-size: ${clampCss(12, 'm')};
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};
    margin: ${clampCss(14, 'm')} 0 0 0;
    text-align: left;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(12, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};
        margin: ${clampCss(14, 't')} 0 0 0;
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(14px, ${pxToVw(14, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(19.6px, ${pxToVw(19.6, 'd')});
        margin: max(14px, ${pxToVw(14, 'd')}) 0 0 0;
    }
`;

const LinkContainer = styled.div`
    border-top: 1px solid #C8C8D0;
    max-width: 1069px;
    padding-top: ${clampCss(40, 'm')};
    text-align: right;
    width: 100%;

    ${MEDIA.TABLET.UP} {
        margin: ${clampCss(32, 't')} 0 0 0;
        padding-top: ${clampCss(48, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        margin: max(32px, ${pxToVw(32, 'd')}) 0 0 0;
        padding-top: max(48px, ${pxToVw(48, 'd')});
    }
`;

const LinkStyle = styled.a`
    ${FONTS.SEMIBOLD}
    color: ${({theme}) => theme.colors.textColor};
    cursor: pointer;
    display: inline-block;
    font-size: ${clampCss(12, 'm')};
    letter-spacing: ${clampCss(0.5, 'm')};
    line-height: ${clampCss(16.8, 'm')};
    padding: 0 ${clampCss(24, 'm')} 0 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    &::after {
        background-image: url(${iconAsBackground(Arrow)});
        background-position: right;
        background-size: ${clampCss(32, 'm')} 100%;
        content: '';
        height: ${clampCss(16, 'm')};
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        transition: width 0.2s 0s ease-in-out;
        width: ${clampCss(16, 'm')};
        will-change: width;
    }

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(12, 't')};
        letter-spacing: ${clampCss(0.5, 't')};
        line-height: ${clampCss(16.8, 't')};
        padding: 0 ${clampCss(24, 't')} 0 0;

        &::after {
            background-size: ${clampCss(32, 't')} 100%;
            height: ${clampCss(16, 't')};
            width: ${clampCss(16, 't')};
        }
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(14px, ${pxToVw(14, 'd')});
        letter-spacing: max(0.5px, ${pxToVw(0.5, 'd')});
        line-height: max(19.6px, ${pxToVw(19.6, 'd')});
        padding: 0 max(24px, ${pxToVw(24, 'd')}) 0 0;

        &::after {
            background-size: max(32px, ${pxToVw(32, 'd')}) 100%;
            height: max(16px, ${pxToVw(16, 'd')});
            width: max(16px, ${pxToVw(16, 'd')});
        }
    }
`;